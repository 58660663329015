import { css } from '@emotion/react'
import React, { Component } from 'react';
import {
    Container,
    Grid
} from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import loadable from "@loadable/component"
import CONSTANTS from '../../helpers/preOfertaConstants'
const OfertaSimuladorCard = loadable(() => import('../pages/OfertaSimuladorCard'))

class ModificarOferta extends Component {

    constructor(props) {
        super(props)

        this.state = {
            mOferCard: false,
            dispersion: CONSTANTS.SPEI,
            oferta: [],
            maximumOffer:0
        }
    }

    async componentDidMount() {
        this.setState({
            oferta: this.props.ofertas,
            maximumOffer: this.props.maximumOffer
        })
    }
    
    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.ofertas !== this.props.ofertas) {
            this.setState({
                oferta: this.props.ofertas
            })
        }
    }

    async aceptarOferta() {
        this.setState({ mOferCard: false })
        await this.props.calculateOffers(0)
    }

    render() {

        const divTransferenciaCss = css`
            margin: auto;

            ${this.props.theme.breakpoints.up('md')} {
                width: 90%;
            }
        `
        const { mOferCard } = this.state
        return (
            <Container>
                <Grid container justifyContent="center">
                    <Grid item xs={12} css={divTransferenciaCss}>
                        {mOferCard &&
                            <OfertaSimuladorCard
                                pageContext={this.props.pageContext}
                                seguros={this.state.seguros}
                                prevIndex={this.state.prevIndex}
                                dispersion={this.state.dispersion}
                                statePadre={this.state}

                                amount={this.state.oferta.amount}
                                paymentPeriod={this.state.oferta.paymentPeriod}
                                paymentTerms={this.state.oferta.paymentTerms}
                                payment={this.state.oferta.payment}
                                id={this.state.oferta.id}
                                // maximaOferta={this.state.oferta.amountTotalCredit}
                                maximaOferta={this.state.maximumOffer}
                                formal
                                updatepagototal={this.props.updatepagototal}
                            />
                        }
                        <br />
                        {mOferCard &&
                            < div style={{ textAlign: 'right' }}>
                                <IconButton aria-label="delete" color="primary" onClick={() => this.aceptarOferta()}>
                                    <DoneIcon fontSize="large" />GUARDAR OFERTA
                                </IconButton>
                            </div>
                        }
                        {!mOferCard &&
                            <div style={{ textAlign: 'right' }}>
                                <IconButton aria-label="delete" color="primary" onClick={() => this.setState({ mOferCard: true })}>
                                    <EditIcon fontSize="large" />MODIFICAR OFERTA
                                </IconButton>
                            </div>
                        }
                    </Grid>
                </Grid>
            </Container >
        );
    }
}

ModificarOferta.propTypes = {

};

ModificarOferta.defaultProps = {
    clabeHelper: 'Recuerda que para poder recibir tú préstamos, la cuenta DEBE estar a tu nombre y tiene que ser la misma en donde recibes el pago de tu nómina.'
}

export default withTheme(ModificarOferta);